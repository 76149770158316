import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { useResidentialPageHeadingSection } from "../hooks/ResidentialPage/useResidentialPageHeadingSection"
import { useResidentialPageMiddleSection } from "../hooks/ResidentialPage/useResidentialPageMiddleSection"
import { useResidentialPageBottomSection } from "../hooks/ResidentialPage/useResidentialPageBottomSection"
import Layout from "../components/Layout/Layout"
import TemplatePageHeaderSection from "../components/TemplatePageHeaderSection/TemplatePageHeaderSection"
import TemplatePageContentSection from "../components/TemplatePageContentSection/TemplatePageContentSection"
import TemplatePageConfigSection from "../components/TemplatePageConfigSection/TemplatePageConfigSection"
import Footer from "../components/Footer/Footer"
import SeoPage from "../components/seo-page"

const ResidentialPage = ({ location }) => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    keyword: {
      en: "photovoltaic system",
      ro: "sistem fotovoltaic",
    },
    slug: {
      en: "Sistem fotovoltaic rezidențial",
      ro: "Sistem fotovoltaic rezidențial",
    },
  }

  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }
  const {
    title: headingTitle,
    subtitle,
    buttonLink: headingButton,
    headingSectionImage,
  } = useResidentialPageHeadingSection()
  const {
    middleSectionServices,
    middleSectionContent: { ...content },
  } = useResidentialPageMiddleSection()
  const {
    title: bottomTitle,
    programText,
    notes,
    backgroundImage,
    buttonLink: bottomButton,
    icon,
  } = useResidentialPageBottomSection()
  return (
    <Layout>
      <SeoPage keyword={getSeoFields("keyword")} slug={getSeoFields("slug")} />
      <TemplatePageHeaderSection
        title={headingTitle}
        subtitle={subtitle}
        buttonLink={headingButton}
        image={headingSectionImage}
        location={location}
      />
      <TemplatePageContentSection
        content={Object.values(content)}
        services={middleSectionServices}
      />
      <TemplatePageConfigSection
        title={bottomTitle}
        programText={programText}
        notes={notes}
        image={backgroundImage}
        icon={icon}
        buttonLink={bottomButton}
      />
      <Footer />
    </Layout>
  )
}

ResidentialPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ResidentialPage
